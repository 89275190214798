var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('header',[_c('div',{staticClass:"headerNav"},[(_vm.addreOption.length)?_c('my-select',{attrs:{"switchId":this.cityId,"options":_vm.addreOption},on:{"checked":_vm.addreCheck}}):_vm._e(),_c('div',{staticClass:"search"},[_c('van-search',{attrs:{"shape":"round","placeholder":"请输入搜索关键词"},on:{"focus":_vm.onfocus},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('keep-alive',[(_vm.langs.length)?_c('my-select',{attrs:{"itemName":"language","options":_vm.langs},on:{"checked":_vm.handleLanguage}}):_vm._e()],1)],1),_c('nav',{staticClass:"nav"},[_c('van-tabbar',{on:{"change":_vm.tabSwitch1},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabList1),function(item,index){return _c('van-tabbar-item',{key:index,scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ? item.icon.active : item.icon.inactive}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.tabTitle)))])])}),1)],1)]),_c('div',{staticClass:"container",attrs:{"id":"mapContainer"}}),_c('div',{staticClass:"recommend",on:{"click":_vm.handleShowModal}},[_c('img',{staticStyle:{"width":"20px","height":"20px","margin-left":"10px"},attrs:{"src":require("../assets/icon/home/luxian.png")}}),_c('button',{staticStyle:{"margin-left":"0.15rem"}},[_vm._v("路线")])]),_c('nut-popup',{style:({
      width: '90%',
      minHeight: '2.9rem',
      maxHeight: '10rem',
      margin: '.3ren 0',
      paddingBottom: '.1rem',
      borderRadius: '0.15rem ',
      textAlign: 'left',
      overflow: 'scroll',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '1.6rem',
    }),attrs:{"id":"none","overlay":false,"position":"bottom","destroy-on-close":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"top"},[_c('div',{staticStyle:{"position":"absolute","right":"0.1rem","bottom":"0rem"},on:{"click":_vm.closePathModel}},[_c('i',{on:{"click":_vm.clickHandle}},[_c('nut-icon',{attrs:{"type":"circle-cross"}})],1)]),_vm._v(" 推荐线路 ")]),_c('div',{staticClass:"pathWrap"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"li",on:{"click":function($event){return _vm.pushLine(index, item)}}},[_c('span',{staticClass:"id"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("线路"+_vm._s(index + 1))])])}),0)]),(_vm.switchs.isShow)?_c('popup',{attrs:{"type":_vm.type,"switchs":_vm.switchs,"active":_vm.active,"data":_vm.switchs.data,"position":this.position}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }