<template>
  <div>
    <!-- 3D渲染盒子 -->
    <iframe id="myIfream" v-if="isShow" :src="ifreamSrc" :frameborder="0" :allowTransparency="true">
    </iframe>
    <!-- 关闭3D模型按钮 -->
    <div @click="isShow = false" v-if="isShow" class="iframe_show">
      <van-icon name="cross" />
    </div>
    <!-- 景点信息弹窗 -->
    <div class="popup" 
     >
      <nut-popup 
      :class="[position === true?'active':'']"
      :style="{
        width: '70%',
        minHeight: '2.9rem',
        margin: '.3ren 0',
        paddingBottom: '.1rem',
        left: '15%',
        bottom: '10%',
        borderRadius: '0.15rem ',
        textAlign: 'left',
      }" :overlay="false" position="bottom " v-model="switchs.isShow" destroy-on-close>
        <div style="width: 100%; overflow: hidden">
          <div class="close" @click="switchs.isShow = false">
            <van-icon name="cross" />
          </div>
          <!-- 景点 -->
          <div class="jingqu">
            <div class="head_bg">
              <!-- 遮罩层 -->
              <div class="zhezhao"></div>
              <img :src="data.imgUrl" alt="" />
            </div>
            <!-- 音频标签 -->
            <audio controls ref="videoDom" v-if="data.explain" :src="data.explain"></audio>

            <div class="buttons">
              <nut-button type="primary" v-if="data.spotAr" shape="circle" small @click="btnAR(data.spotAr)"
                class="AR_button">
                {{ $t("AR导览") }}
              </nut-button>
              <nut-button type="primary" small v-if="data.spot3d" shape="circle" @click="but3D(data.spot3d)"
                class="nav_button">
                {{ $t("3D") }}
              </nut-button>

              <nut-button type="primary" v-if="data.spotVr" shape="circle" small @click="btnVR(data.spotVr)"
                class="VR_button">
                {{ $t("VR全景") }}
              </nut-button>
              <nut-button type="primary" small shape="circle" @click="show = true" class="nav_button">
                {{ $t("去这里") }}
              </nut-button>
              <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action
                @select="onSelect" />

              <nut-button type="primary" small shape="circle" class="nav_button" @click="leaveWord">
                {{ $t("留言") }}
              </nut-button>
              <div class="control" @click="play" v-if="data.explain" :src="data.explain">
                <van-icon :name="icon" />
              </div>
              <!-- 更多 -->
              <nut-button type="primary" small shape="circle" class="more" @click="gengduo">
                <van-icon :name="iconName" />
              </nut-button>
            </div>
          </div>

          <div class="head" style="padding-left: 0.1rem">
            <!-- 景区介绍 -->
            <h3>
              {{ $t(data.name) }}
            </h3>
            <!-- 收藏 -->
            <div class="amount" v-if="type == '景点' || type == '美食' || type == '住宿'">
              <van-rate v-model="value" icon="star" void-icon="star-o" :count="1" color="#ffd21e" @change="onChange1" />
            </div>
          </div>
          <!-- 播放控件 -->
        </div>
        <!-- 详情 -->
        <div class="main" v-if="showDetali">
          <h2>
            {{ homeData.name || "" }}
          </h2>
          <div class="content">
            {{ homeData.introduce || "" }}
          </div>

          <!-- 景点详情部分 -->
          <ul class="label">
            <li v-if="homeData.optimalTimePeriod">
              最佳旅游时间: {{ homeData.optimalTimePeriod }}
            </li>
            <li v-if="homeData.scenicSpotLevel">
              级别: {{ homeData.scenicSpotLevel }}
            </li>
            <li v-if="homeData.admissionTicket">
              门票:{{ homeData.admissionTicket }}
            </li>
            <li v-if="homeData.address">地址: {{ homeData.address }}</li>
            <li v-if="homeData.telephone">电话: {{ homeData.telephone }}</li>
          </ul>

          <!-- 美食详情 -->
          <ul class="label" v-if="homeData.deliciousFoodName">
            <!-- <li>美食名称: {{ homeData.deliciousFoodName }}</li>
            <li>详解: {{ homeData.deliciousFoodIntroduce }}</li> -->
          </ul>

          <img v-if="homeData.imgUrl" :src="homeData.imgUrl" alt="" />
          <!-- 视频标签 -->
          <nut-video v-if="this.sources.src" class="video" :sources="sources" :options="options" ref="videoDom"
            style="width: 100%; height: 1.5rem">
          </nut-video>
        </div>
      </nut-popup>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
  name: "popup",
  data() {
    return {
      IsShowDialog: false,
      ifreamSrc: "",
      starCount: 1,
      isShow: false,
      value: 0,
      flag: false,
      num: 10,
      off: false,
      starColor: "red",
      content: "",
      activeNames: [],
      icon: "play",
      options: {
        controls: false,
        playsinline: true,
      },
      show: false,
      list1: [],
      list2: [],
      actions: [
        { name: "百度地图" },
        { name: "高德地图" },
        { name: "腾讯地图" },
      ],
      ifreamDom: "",
      // ============================================================
      sources: [
        {
          src: "https://storage.jd.com/about/big-final.mp4?Expires=3730193075&AccessKey=3LoYX1dQWa6ZXzQl&Signature=ViMFjz%2BOkBxS%2FY1rjtUVqbopbJI%3D",
          type: "video/mp4",
        },
      ],
      options: {
        controls: true,
      },
      list: [],
      showDetali: false,
      iconName: "arrow-down",
    };
  },
  computed: {
    ...mapState(["homeData"]),
  },
  watch: {
    data(newVal, oldVal) {
      this.showDetali = false;
      this.getChange1list();
    },
    immediate: true,
    deep: true,
  },
  props: {
    switchs: Object,
    data: Object,
    type: String,
    active: Number,
    position:Boolean,
  },
  created() {
    if (sessionStorage.getItem("wxUser") == null) {
      this.off = true;
    } else {
      this.off = false;
      this.getChange1list();
    }
    let type = {
      1: "景点",
      2: "美食",
      3: "住宿",
      4: "文化",
      5: "特产",
    };
    if (this.$route.query.type) this.type = type[this.$route.query.type];
    this.flag = !this.data.flag;
  },
  methods: {
    // 查询是否收藏
    getChange1list() {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId == null) {
        return false;
      }
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      params.append("userId", userId.id);
      params.append("abbreviation", abbreviation);
      let that = this;
      // 发送请求  获取数据
      this.$axios.post("/api/msg/getUserCollectionList", params).then((res) => {
        let result = res.data;
        let CollectList = res.data.list2;
        that.list1 = result.list1;
        that.list2 = result.list2;
        let name = this.data.name;
        if (CollectList.length) {
          for (let i = 0; i < CollectList.length; i++) {
            if (CollectList[i].name == name) {
              that.value = 2;
              return;
            } else {
              that.value = 0;
            }
          }
        }
      });
    },
    ...mapMutations(["sethomeData"]),
    // 视屏控件
    play(e) {
      if (this.data.explain) {
        var audio = document.getElementsByTagName("audio")[0];
        if (this.icon == "play") {
          this.icon = "pause";
          audio.play();
        } else {
          this.icon = "play";
          audio.pause();
        }
      } else {
        console.log("暂无资源");
      }
    },
    btnAR(src) {
      this.$router.push({
        path: "/iframe",
        query: {
          src: src,
        },
      });
      // window.location.href = src;
    },
    btnVR(src) {
      this.$router.push({
        path: "/iframe",
        query: {
          src: src,
        },
      });
      // window.location.href = src;
    },
    but3D(src) {
      if (this.data.ifreamButton == 1) {
        this.isShow = true;
        this.ifreamSrc = this.data.spot3d;
      } else {
        window.location.href = src;
      }
    },
    //收藏控件
    onChange1() {
      event.stopPropagation();
      // 判断是否登录
      // 1、未登录
      if (this.off) {
        // this.switchs.isShow = false;
        this.value = 0;
        this.$toast.warn("请先登录微信");
        return;
      }
      // 2、已登录
      this.value = this.flag ? this.value + 1 : this.value - 1;
      // this.flag = !this.flag;
      let paramsType = {
        景点: {
          src1: "/api/msg/getCollectionScenicSpot",
          scenicSpotId: this.data.id,
          name: "scenicSpotId",
          src2: "/getCancelScenicSpot",
        },
        美食: {
          src1: "/api/msg/getCollectionDeliciousFood",
          deliciousFoodId: this.data.id,
          name: "deliciousFoodId",
          src2: "/getCancelDeliciousFood",
        },
        住宿: {
          src1: "/api/msg/getCollectionLodging",
          lodgingId: this.data.id,
          name: "lodgingId",
          src2: "/getCancelLodging",
        },
      };
      if (this.value == 2) {
        this.getCollect(
          paramsType[this.type].src1,
          paramsType[this.type].name,
          paramsType[this.type].scenicSpotId ||
          paramsType[this.type].deliciousFoodId ||
          paramsType[this.type].lodgingId
        );
      } else {
        this.deleteCollect(paramsType[this.type].src2);
      }
    },
    // 添加收藏
    getCollect(src1, name, id) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId == null) {
        return false;
      }
      params.append("userId", userId.id);
      params.append(name, id);
      this.$axios.post(src1, params).then((res) => {
        // this.getChange1list();
        // this.starColor = "red";
        this.flag = false;
        // this.value = 1;
        // console.log(res);
      });
    },
    // 取消收藏
    deleteCollect(src) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId == null) {
        return false;
      }
      let id = this.data.id;
      params.append("userId", userId.id);
      params.append("id", id);
      this.$axios.post("/api/msg/" + src, params).then((res) => {
        console.log(res);
        // this.getChange1list();
        // this.value = 0;
        // this.starColor = "white";
        this.flag = true;
      });
    },
    // 更多
    gengduo() {
      this.sethomeData(this.data); // 将数据存放在vuex
      // this.$router.push("/conTent");
      this.showDetali = !this.showDetali;
      this.iconName = this.showDetali ? "arrow-up" : "arrow-down";
      this.list = this.homeData;
    },
    //留言
    leaveWord() {
      this.sethomeData(this.data); // 将数据存放在vuex
      this.$router.push({
        path: "/leaveWord",
        query: { active: this.active },
      });
    },
    //去这里
    onSelect(e) {
      // 拉起地图导航
      if (e.name == "百度地图") {
        window.location.href = `http://api.map.baidu.com/marker?location=${this.data.trPosition.latitude},${this.data.trPosition.longitude}&title=${this.data.scenicSpotName}&content=${this.data.scenicSpotName}&output=html&src=webapp.baidu.openAPIdemo   `;
      }
      if (e.name == "高德地图") {
        window.location.href = `https://uri.amap.com/marker?position=${this.data.trPosition.longitude},${this.data.trPosition.latitude} &name=${this.data.scenicSpotName}&coordinate=gaode&callnative=1`;
      }
      if (e.name == "腾讯地图") {
        window.location.href = ` http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.data.trPosition.latitude},${this.data.trPosition.longitude};addr:${this.data.scenicSpotName}`;
      }
    },
    iframe() {
      console.log(123);
    },
  },
};
</script>

<style scoped lang="less">
iframe {
  position: absolute;
  top: 13vh;
  width: 100%;
  height: 79%;
  z-index: 9999;
  // 添加阴影
  // background: rgba(0, 0, 0, 0.3);
  // z-index: 100000;
}


.iframe_show {
  position: absolute !important;
  top: 16vh !important;
  right: 0.1rem;
  width: 0.5rem;
  height: 0.5rem;
  text-align: center;
  line-height: 0.5rem;
  border: 1px solid black;
  border-radius: 50%;
  color: black;
  z-index: 100001;
  font-size: 0.4rem;
}

// 按钮
.buttons {
  position: relative;
  padding: 0 0.1rem;
  width: 100%;

  /deep/ .nut-button {
    margin: 0.1rem 0.06rem;
    background-color: #fff;
    color: #555;
    border: 0.01rem solid #aaa;
    border-radius: 0.1rem;
  }

  /deep/ .nut-button.small {
    padding: 0 0.1rem;
    height: 0.5rem;
    line-height: 0.4rem;
    font-size: 0.2rem;
  }

  /deep/ .popup-box {
    overflow-y: auto;
  }

  .top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-0.25rem);
  }

  /deep/ .collapse-title {
    padding-right: 0.36rem;
    font-size: 0.3rem;
    // 多行行文本溢出显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    padding: 0.2rem 0.2rem;
    text-indent: 2em;
    font-size: 0.2rem;
  }
}

// rate大小
.amount /deep/.nut-rate-item {
  width: 21px !important;
  height: 21px !important;
}

// 关闭按钮
.close {
  position: absolute;
  right: 0.1rem;
  top: 0;
  width: 0.4rem;
  height: 0.4rem;
  color: #fff;
  font-size: 0.4rem;
  z-index: 100;
}

// 留言
.message_board {
  position: absolute;
  top: -1.2rem;
  right: 0.1rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  z-index: 10000;
}

.content {
  text-indent: 2em;
}

.abc {
  padding: 0.1rem;
  font-size: 0.3rem;
}

.title {
  text-align: center;
  font-size: 0.3rem;
  line-height: 0.6rem;
}

// 更多
.more {
  float: right;
  width: 0.45rem !important;
  height: 0.45rem !important;
  border-radius: 50% !important;
  text-align: center;
  line-height: 0.45rem;
}

// 景区图片
.head_bg {
  width: 100%;
  height: 1.5rem;

  img {
    width: 100%;
    height: 100%;
  }
}

// 遮罩层
.zhezhao {
  position: absolute;
  width: 100%;
  height: 1.5rem;
  background-color: rgba(0, 0, 0, 0.4);
}

// 音频视频部分
audio {
  position: absolute;
  right: 10000rem;
}

// 景区标题和收藏
.head {
  position: absolute;
  top: 0.34rem;
  width: 100%;
  color: #fff;

  h3 {
    display: inline-block;
    margin: 0.08rem 0;
    margin-right: 0.1rem;
    font-size: 0.28rem;
    max-width: 60%;
    // 单行文本溢出显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .amount {
    // position: absolute;
    // top: 0;
    // left: 60%;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    min-width: 0.8rem;
    vertical-align: middle;
  }
}

// 控件
.control {
  display: inline-block;
  width: 0.46rem;
  height: 0.46rem;
  background: #f24e4e;
  border-radius: 50%;
  text-align: center;
  line-height: 0.46rem;
  color: #fff;
}

.main {
  max-height: 6rem;
  padding: 0.1rem 0.2rem;
  overflow: scroll;

  h2 {
    font-size: 0.32rem;
    line-height: 0.6rem;
  }

  p {
    font-size: 0.2rem;
  }

  // 内容讲解
  .content {
    margin: 0.2rem 0;
    text-indent: 2em;
  }

  .label {
    font-size: 0.26rem;
    line-height: 0.36rem;
  }

  /deep/ .nut-video .nut-videoplayer {
    margin-top: 0.3rem;
    width: 96%;
    height: 20vh;
  }

  img {
    width: 100%;
  }
}
.active{
  bottom: 30% !important;
}
</style>
