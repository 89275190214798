<template>
  <div class="home">
    <header>
      <!-- 搜索框 -->
      <div class="headerNav">
        <my-select
          v-if="addreOption.length"
          :switchId="this.cityId"
          :options="addreOption"
          @checked="addreCheck"
        />
        <div class="search">
          <van-search
            v-model="value"
            shape="round"
            placeholder="请输入搜索关键词"
            @focus="onfocus"
          />
        </div>
        <keep-alive>
          <my-select
            v-if="langs.length"
            itemName="language"
            :options="langs"
            @checked="handleLanguage"
          />
        </keep-alive>
      </div>
      <!-- 导航分类 -->
      <nav class="nav">
        <van-tabbar v-model="active" @change="tabSwitch1">
          <van-tabbar-item v-for="(item, index) in tabList1" :key="index">
            <span>{{ $t(item.tabTitle) }}</span>
            <template #icon="props">
              <img
                :src="props.active ? item.icon.active : item.icon.inactive"
              />
            </template>
          </van-tabbar-item>
        </van-tabbar>
      </nav>
    </header>
    <!-- 地图盒子 -->
    <div class="container" id="mapContainer"></div>

    <!-- </div> -->
    <div class="recommend" @click="handleShowModal">
      <img
        style="width: 20px; height: 20px; margin-left: 10px"
        src="../assets/icon/home/luxian.png"
      />
      <button style="margin-left: 0.15rem">路线</button>
    </div>
    <nut-popup
      id="none"
      :style="{
        width: '90%',
        minHeight: '2.9rem',
        maxHeight: '10rem',
        margin: '.3ren 0',
        paddingBottom: '.1rem',
        borderRadius: '0.15rem ',
        textAlign: 'left',
        overflow: 'scroll',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '1.6rem',
      }"
      :overlay="false"
      position="bottom"
      v-model="showModal"
      destroy-on-close
    >
      <div class="top">
        <div
          @click="closePathModel"
          style="position: absolute; right: 0.1rem; bottom: 0rem"
        >
          <i @click="clickHandle">
            <nut-icon type="circle-cross"></nut-icon>
          </i>
        </div>
        推荐线路
      </div>
      <div class="pathWrap">
        <div
          class="li"
          v-for="(item, index) in list"
          :key="index"
          @click="pushLine(index, item)"
        >
          <span class="id">{{ index + 1 }}</span>
          <span style="margin-left: 10px">线路{{ index + 1 }}</span>
        </div>
      </div>
    </nut-popup>
    <!-- 景区信息弹出框 -->
    <popup
      v-if="switchs.isShow"
      :type="type"
      :switchs="switchs"
      :active="active"
      :data="switchs.data"
      :position="this.position"
    ></popup>
  </div>
</template>

<script>
import { i18n, vantLocales } from "../lang/index"
import { mapState, mapMutations } from "vuex"
// 导入底部导航栏组件
import popup from "../components/popup"
import { teMap } from "../tool/common"
import MySelect from "../components/MySelect.vue"
import wx from "weixin-js-sdk"
import { json } from "body-parser"
export default {
  components: {
    popup,
    MySelect,
  },
  computed: {
    ...mapState(["PathData"]),
  },
  data () {
    return {
      showModal: false,
      // 分享
      options: [
        [
          { name: "微信", icon: "wechat" },
          { name: "朋友圈", icon: "wechat-moments" },
          { name: "微博", icon: "weibo" },
          { name: "QQ", icon: "qq" },
        ],
      ],
      wxConfig: {
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "", // 必填，公众号的唯一标识
        timestamp: "", // 必填，生成签名的时间戳
        nonceStr: "", // 必填，生成签名的随机串
        signature: "", // 必填，签名
        jsApiList: [
          "checkJsApi",
          "updateAppMessageShareData",
          "updateTimelineShareData",
        ], // 必填，需要使用的JS接口列表
      },
      shareInfo: {
        title: "云游青海",
        link: "",
        imgUrl: "",
        desc: "",
      },
      showShare: false,
      active: 0,
      searchData: {}, //搜索传参
      el: "mapContainer", //
      currCenter: null, //當前中心點
      markerCluster: null,
      maps: null,
      clickPoint: [], //被点击的点
      currGroundLayer: null, //
      earthlayer: null, //自定义图层
      value: "",
      i: 0,
      switchs: {
        isShow: false,
        data: {},
      },
      type: "景点",
      // 定位切换
      addreOption: [],
      // 语言切换
      langs: [
        { language: "汉语", abbreviation: "zhCN" },
        { language: "藏语", abbreviation: "ti" },
        { language: "日文", abbreviation: "jaJP" },
        { language: "英文", abbreviation: "enUS" },
        { language: "韩文", abbreviation: "Korean" },
      ],
      // 导航分类
      // 导航分类
      tabList1: [
        {
          tabTitle: "景点",
          icon: {
            active: require("../assets/icon/home/jingdian.png"),
            inactive: require("../assets/icon/home/jingdian1.png"),
          },
        },
        {
          tabTitle: "美食",
          icon: {
            active: require("../assets/icon/home/meishi.png"),
            inactive: require("../assets/icon/home/meishi1.png"),
          },
        },
        {
          tabTitle: "住宿",
          icon: {
            active: require("../assets/icon/home/zhusu.png"),
            inactive: require("../assets/icon/home/zhusu1.png"),
          },
        },
        {
          tabTitle: "文化",
          icon: {
            active: require("../assets/icon/home/wenhua.png"),
            inactive: require("../assets/icon/home/wenhua1.png"),
          },
        },
        {
          tabTitle: "特产",
          icon: {
            active: require("../assets/icon/home/techan.png"),
            inactive: require("../assets/icon/home/techan1.png"),
          },
        },
      ],
      latLngArr: [],
      list: [
        // 景区标点数据
        [],
        // 美食
        [],
        // 住宿
        [],
        // 文化
        [],
        // 特产
        [],
      ],
      data: {},
      fun: null,
      //线路信息
      geometries: [
        {
          id: "polyline", //折线图形数据的标志信息
          styleId: "polyline", //样式id
          paths: [
            new TMap.LatLng(33.9711, 100.752246),
            new TMap.LatLng(36.435463, 102.876613),
            new TMap.LatLng(30.041407, 116.274738),
          ], //折线的位置信息
          properties: {
            //折线的属性数据
            title: "customStyle",
          },
        },
      ],
      polylineId: null,
      mapList: null,
      currentAddre: "",
      position: false,
      // 城市id
      cityId: null,
      switchId: null
    }
  },
  created () {
    this.parameter()
    this.addUser()
    // =====================================
    let abbreviation = JSON.parse(
      sessionStorage.getItem("abbreviation")
    ).travelAbbreviation
    let params = new FormData()
    params.append("abbreviation", abbreviation)
    if (abbreviation) {
      this.$axios.post("/api/map/getTrRecommendedRoute", params).then((res) => {
        if (res.code == 0) {
          this.list = res.data
        } else {
          this.$toast.fail("请求失败，请刷新页面")
        }
      })
    }
  },
  mounted () {
    this.Sign()
  },
  watch: {
    type: {
      handler: function (newVal, oldVal) {
        if (this.currentAddre) {
          this.getPoints()
        }
      },
      // deep: true,
      // immediate: true,
    },
  },
  inject: ["reload"],
  methods: {
    // 在页面渲染时查看是否携带id
    parameter () {
      let id = window.location.search.substr(1)
      let str = id.split("=")
      console.log(str[1], 'lujing')
      if (!this.$route.query == {}) {
        str[1] = this.switchId
      }
    },
    async Sign () {
      this.searchData = this.$route.query
      if (this.$route.query) {
        this.active =
          Object.keys(this.searchData).length > 0
            ? this.searchData.type - 1
            : 0
      }
      await this.cityList()
      // 1.判断用户是否微信登录
      if (JSON.parse(sessionStorage.getItem("wxUser"))) {
        this.getMyLocation() // 定位用户当前位置信息
      }
      // 2.判断是否从search跳转
      if (this.searchData.name) {
        // 2.1从search跳转 搜索目标定位选中状态并展示弹框信息
        let url = [
          "/getScenicSpotPosition", //景点
          "/getDeliciousFoodPosition", //美食
          "/getLodgingPosition", //住宿
          "/getCulturePosition", //文化
          "/getSpecialtyPosition", // 特产
        ]
        let abbreviation = JSON.parse(
          sessionStorage.getItem("abbreviation")
        ).travelAbbreviation
        console.log(abbreviation)
        let params = new FormData()
        params.append("abbreviation", abbreviation)
        this.$axios.post("/api/map" + url[this.active], params).then((res) => {
          if (res.code == 0) {
            let arr = this.linePointGeometry(res.data)
            let geometry = ""
            arr.map((item) => {
              if (item.id == this.searchData.id) {
                geometry = item
              }
            })
            // 修改搜索景点的icon状态并打开弹框显示景点信息
            if (this.switchs.isShow) {
              this.switchs.isShow = false
            }
            this.switchs.isShow = true
            geometry.styleId = geometry.styleClickIcon
            geometry.isClick = true
            this.switchs.data = geometry
            this.cancelAllClickEvent()
            this.clickPoint = [this.switchs.data]
            setTimeout(() => {
              this.markerCluster.updateGeometries(geometry)
            }, 0)
          }
        })
      } else {
        let lineArr
        if (this.$route.query.pathData) {
          this.active = 0
          lineArr = this.$route.query.pathData
        }
        // 2.2非search跳转 根据目标类型展示所有同类型目标定位信息
        // 判断定位点类型
        if (lineArr == undefined) {
          lineArr = {}
        }
        if (!this.active && Object.keys(lineArr).length) {
          // 调用线路函数
          let lienData = await this.pathData()
          await this.polyline(lienData) // 初始化推荐线路
          this.moveLayers() // 获取图层id
        }
      }
    },
    ...mapMutations(["setPathData"]),
    clickHandle () {
      setTimeout(() => {
        this.position = false
      }, 300)
    },
    handleShowModal () {
      this.position = true
      this.showModal = true
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation
      if (abbreviation) {
        this.$axios
          .post("/api/map/getTrRecommendedRoute", { abbreviation })
          .then((res) => {
            if (res.code == 0) {
              this.list = res.data
            } else {
              this.$toast.fail("请求失败，请刷新页面")
            }
          })
      }
    },

    vantLocales,
    handleLanguage (item) {
      this.vantLocales(item)
      this.reload()
    },
    // 获取签名
    getSignature () {
      if (window.location.href.indexOf("code") != -1) {
        this.shareInfo.link = window.location.href.split("?")[0]
      } else {
        this.shareInfo.link = window.location.href.split("#")[0]
      }
      let Base = require("js-base64").Base64

      let params = new FormData()
      let baseUrl = Base.encode(window.location.href.split("#")[0])
      params.append("address", baseUrl)
      let that = this
      this.$axios.post("/wx/getShareConfig", params).then((res) => {
        if (res.code == 0) {
          this.shareInfo.link = res.data.url
          this.shareInfo.link = `${this.shareInfo.link}?id=${this.cityId}`
          this.wxConfig = Object.assign(this.wxConfig, res.data)
          this.wxConfig.url = window.location.href.split("#")[0]
          console.log(this.wxConfig)
          wx.config(this.wxConfig)
          wx.ready(function () {
            wx.checkJsApi({
              jsApiList: [
                // 所有要调用的 API 都要加到这个列表中
                "updateAppMessageShareData", "updateTimelineShareData", "onMenuShareTimeline", "onMenuShareAppMessage"
              ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (res) {
                console.log(res, "333333")
              },
            })
            wx.updateAppMessageShareData({
              title: that.shareInfo.title,
              desc: that.shareInfo.desc,
              link: that.shareInfo.link,
              imgUrl: that.shareInfo.imgUrl,
              success: function (res) {
                console.log(res, "微信分享成功")
              },
              fail: function (res) {
                console.log(res, "微信分享失败")
              },
            })
            wx.updateTimelineShareData({
              title: that.shareInfo.title,
              desc: that.shareInfo.desc,
              link: that.shareInfo.link,
              imgUrl: that.shareInfo.imgUrl,
              success: function (res) {
                console.log(res, "微信分享成功2")
              },
              fail: function (res) {
                console.log(res, "微信分享失败2")
              },
            })
            wx.onMenuShareTimeline({
              title: that.shareInfo.title,
              desc: that.shareInfo.desc,
              link: that.shareInfo.link,
              imgUrl: that.shareInfo.imgUrl,
            })

            wx.onMenuShareAppMessage({
              title: that.shareInfo.title,
              desc: that.shareInfo.desc,
              link: that.shareInfo.link,
              imgUrl: that.shareInfo.imgUrl,
            })
          })
          wx.error(function (err) {
            console.log(err, "444444")
            that.$toast.fail("验证失败")
          })
        }
      })
    },
    // 获取用户信息
    addUser () {
      if (!this.getUrlParam("code")) return false
      let code = this.getUrlParam("code")
      let state = this.getUrlParam("state")
      let parmas = new FormData()
      parmas.append("code", code)
      parmas.append("state", state)
      this.$axios
        .post("/wx/notify/code", parmas)
        .then((res) => {
          sessionStorage.setItem("token", res.data.token)
          sessionStorage.setItem("wxUser", JSON.stringify(res.data.wxUser))
        })
        .catch((error) => {
          console.log(error, "用户信息")
        })
    },
    //  获取用户定位信息
    getMyLocation () {
      let data = {
        key: "LZQBZ-DBT3X-3LH4J-74MVL-VTSYO-SGBKO", //这个key就是你申请的密钥，替换这个key
        output: "jsonp",
      }
      let url = "https://apis.map.qq.com/ws/location/v1/ip" //地理位置信息的接口
      this.$jsonp(url, data)
        .then((res) => {
          this.showPosition(res.result.location)
        })
        .catch((error) => {
          // this.$toast.fail("获取定位失败");
        })
    },
    // 发送定位信息
    showPosition (position) {
      // 将position对象传递给后端
      let parmas = new FormData()
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null
      let longitude = position.lng
      let latitude = position.lat
      parmas.append("userId", userId.id)
      // parmas.append("userId", 4);
      parmas.append("longitude", longitude)
      parmas.append("latitude", latitude)
      this.$axios.post("/api/msg/addUserFootprint", parmas).then((res) => {
        if (res.code === 500) {
          console.log(res.code, res.result, "定位信息")
        }
      })
    },
    showErr () {
      this.getMyLocation() // 定位失败再请求定位，测试使用
    },
    // 切换定位列表请求
    cityList () {
      this.$axios.post("/api/map/getProjectMapData").then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.addreOption = res.data
          this.currentAddre = this.addreOption[0]
          this.shareInfo.title = this.currentAddre.title
          this.shareInfo.desc = this.currentAddre.describe
          this.shareInfo.imgUrl = this.currentAddre.imgUrl
          this.addreCheck(this.currentAddre)
          this.getSignature()
        }
      })
    },
    // 切换城市坐标
    async addreCheck (data) {
      this.cityId = data.id
      this.currentAddre = data
      this.shareInfo.title = this.currentAddre.title
      this.shareInfo.desc = this.currentAddre.describe
      this.shareInfo.imgUrl = this.currentAddre.imgUrl
      wx.updateAppMessageShareData(this.shareInfo)
      wx.updateTimelineShareData(this.shareInfo)
      sessionStorage.setItem("abbreviation", JSON.stringify(this.currentAddre))
      await this.getPoints()
      this.latLngArr = this.list[0] // 获取景点信息
      await this.init() //初始化地图
      await this.layer() //获取瓦片图层
      await this.moveLayer() // 添加瓦片地图
    },

    // 导航栏
    async tabSwitch1 () {
      this.showModal = false
      // 切换分类时 删除推荐线路
      if (this.polylineId !== null) {
        this.polylineId.remove("polyline")
      }
      // 关闭弹窗
      if (this.switchs.isShow) {
        this.switchs.isShow = false
      }
      // 将类型传递给弹框子组件
      this.type = this.tabList1[this.active].tabTitle
      // 判断类型调用接口数据
      this.currMapInfo = this.list[this.active]
      await this.getPoints()

      this.drawPoint()
    },

    // 地图初始化
    async init () {
      let data = JSON.parse(sessionStorage.getItem("abbreviation"))
      if (data.travelAbbreviation) {
        let SWPonit = data.lowerLeft.split(",")
        let NEPoint = data.upperRight.split(",")
        let centerPoint = data.centerPoint.split(",")
        let imageSW = new TMap.LatLng(SWPonit[0], SWPonit[1]) //西南
        let imageNE = new TMap.LatLng(NEPoint[0], NEPoint[1]) //东北 地图边界点
        let boundary = new TMap.LatLngBounds(imageSW, imageNE)
        this.currCenter = new window.TMap.LatLng(
          centerPoint[0],
          centerPoint[1]
        )
        if (!this.maps) {
          this.maps = new window.TMap.Map(
            document.getElementById("mapContainer"),
            {
              center: this.currCenter, // 设置地图中心点坐标
              zoom: 8, // 设置地图缩放级别
              minZoom: 7, //此处设置地图的缩放级别  最小值是6
              maxZoom: 13, //此处设置地图的缩放级别  最大值是7
              viewMode: "2D",
              // pitch: 43.5, // 设置俯仰角
              // rotation: 0, // 设置地图旋转角度
            }
          )
        } else {
          this.maps.panTo(this.keysurrCenter)
          this.maps.zoomTo(8)
        }
        this.maps.setBoundary(boundary) //设置地图边界
      }
    },

    // ====================================路线相关操作=====================================
    // 关闭路线弹框
    closePathModel () {
      this.showModal = false
      // 删除推荐线路
      if (this.polylineId !== null) {
        this.polylineId.remove("polyline")
      }
      this.getPoints()
    },
    async pushLine (i, val) {
      // this.setPathData(val); //将数据存储
      // let lienData = await this.pathData();
      // 销毁已有的路线图层
      if (this.polylineId) {
        this.polylineId.destroy()
      }
      // 清除初始化点位
      await this.clearPoints()
      let linePoints = val.map((item) => {
        return new TMap.LatLng(item.latitude, item.longitude)
      })
      // await this.drawPoint();
      // // 初始化推荐线路
      // await this.polyline(linePoints);
      // // 提高图层级别
      // this.moveLayers();
      this.latLngArr = null
      this.latLngArr = this.linePointGeometry(val)
      this.drawPoint()
      // 初始化推荐线路
      this.polyline(linePoints)
      // 提高图层级别
      this.moveLayers()
    },
    //  创建polyline对象（路线样式）
    polyline (pathData) {
      this.polylineId = new TMap.MultiPolyline({
        id: "polyline-layer",
        map: this.maps,
        dashArrow: true,
        zIndex: 9999,
        styles: {
          polyline: new TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 4, //折线宽度
            borderWidth: 1, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
            dashArray: [7, 10], //虚线
          }),
        },
        geometries: [
          {
            id: "polyline", //折线图形数据的标志信息
            styleId: "polyline", //样式id
            paths: pathData, //折线的位置信息
            properties: {
              //折线的属性数据
              title: "customStyle",
            },
          },
        ],
      })
    },
    // 获取路线点位信息
    // pathData() {
    //   // let data = [];
    //   // this.PathData.forEach((item) => {
    //   //   data.push(
    //   //     new TMap.LatLng(item.latitude, item.longitude)
    //   //   );
    //   // });
    //   // return data;
    //   return this.PathData;
    // },
    // 提高推荐线路层级  展示在瓦片地图上层
    moveLayers () {
      let layerId = this.polylineId.id // 获取图层ID
      // 根据输入 LAYER_LEVEL 常量调整 layerId 对应图层的渲染层级 ，其中layerId可以通过图层getId方法获取。
      // TMap.constants.LAYER_LEVEL 代表图层级别常量，见帮助文档（https://lbs.qq.com/webApi/javascriptGL/glDoc/docIndexMap）
      this.maps.moveLayer(layerId, TMap.constants.LAYER_LEVEL.TEXT)
    },
    // ===================================定位点相关操作====================================
    // 添加定位图标到地图
    async drawPoint () {
      this.clickPoint = []
      let self = this
      let styleObj = this.showModal
        ? this.myPointStyle()
        : this.initPointStyle()
      if (this.markerCluster) {
        this.markerCluster.setMap(null)
        this.markerCluster = null
      }
      // if (!this.markerCluster) {
      //创建点对象
      this.markerCluster = new TMap.MultiMarker({
        id: "cluster", //图层id
        map: this.maps, //设置点聚合显示在哪个map对象中（创建map的段落省略）
        geometries: this.latLngArr, //初始化的点
        styles: styleObj,
      })
      // 标记点添加点击事件
      this.markerCluster.on("click", (e) => {
        // console.log('点位事件');
        let Center = new window.TMap.LatLng(e.latLng.lat, e.latLng.lng)
        this.maps.panTo(Center)

        self.switchs.isShow = true
        e.geometry.styleId = e.geometry.styleClickIcon
        e.geometry.isClick = true
        self.switchs.data = e.geometry
        self.cancelAllClickEvent()
        self.markerCluster.updateGeometries(self.switchs.data)
        self.clickPoint = [self.switchs.data]
        // console.log();
        // self.setStyleIcon(e.geometry);
      })
      // } else {
      //   this.updatePoints();
      // }
    },
    // 更新markerCluster图层当前所有点位
    updatePoints () {
      this.clearPoints()
      this.markerCluster.add(this.latLngArr) //绘制当前latLngArr定位点
    },
    // 清除markerCluster图层当前所有点位
    clearPoints () {
      // let delLatLngArr = this.markerCluster
      //   .getGeometries()
      //   .map((item) => item.id);
      // console.log(this.markerCluster.getGeometries());
      // this.markerCluster.remove(delLatLngArr); //删除上个tabs的地图点
      // console.log(this.markerCluster);
      this.markerCluster.setMap(null)
      this.marker = null
    },
    //遍历所有当前点，重置所有节点样式
    cancelAllClickEvent () {
      if (this.clickPoint.length <= 0) return
      this.clickPoint = this.clickPoint.map((item) => {
        return { ...item, isClick: false, styleId: item.styleIcon }
      })
      this.markerCluster.updateGeometries(this.clickPoint)
      this.clickPoint = []
    },
    // 获取点位数据
    getPoints () {
      let url = [
        "/getScenicSpotPosition", //景点
        "/getDeliciousFoodPosition", //美食
        "/getLodgingPosition", //住宿
        "/getCulturePosition", //文化
        "/getSpecialtyPosition", // 特产
      ]
      let parmas = new FormData()
      parmas.append("abbreviation", this.currentAddre.travelAbbreviation)
      this.$axios.post("/api/map" + url[this.active], parmas).then((res) => {
        if (res.code == 0) {
          this.latLngArr = this.initPointGeometry(res.data)
          this.drawPoint()
        } else {
          this.$toast.fail("请求失败，请刷新页面")
        }
      })
    },
    // "初始化"定位点点标记样式
    initPointStyle () {
      let styleObj = {}
      // 创建5中选中和默认marker图层样式
      new Array(5).fill(1).forEach((item, i) => {
        styleObj["marker" + i] = new TMap.MarkerStyle({
          width: 40,
          height: 40,
          anchor: { x: 0, y: 0 },
          src: this.setStyle(i),
        })
        styleObj["c-marker" + i] = new TMap.MarkerStyle({
          width: 40,
          height: 40,
          anchor: { x: 0, y: 0 },
          src: this.setStyleIcon(i),
        })
      })
      return styleObj
    },
    // 自定义定位点样式
    myPointStyle () {
      return {
        default: new TMap.MarkerStyle({
          width: 40, // 宽度
          height: 40, // 高度
          anchor: { x: 20, y: 20 }, // 锚点位置
          src: "http://ilab-assets.oss-cn-zhangjiakou.aliyuncs.com/HanChaoMap/94676928b4a845c1b8d6a6415fb820c1.png", // 标注点图片url或base64地址
          color: "#000", // 标注点文本颜色
          size: 20, // 标注点文本文字大小
          offset: { x: 0, y: -4 }, // 标注点文本文字基于direction方位的偏移属性
        }),
      }
    },
    // 切换分类点位icon
    setStyle (type) {
      let baseIconUrl = `http://ilab-assets.oss-cn-zhangjiakou.aliyuncs.com/HanChaoMap/`
      let png = [
        "88975231d6114568b642bb1d788a9c3a.png",
        "7b24328c94a34074b0dfca988cab7ef2.png",
        "ba01321d305048e0ae755eb28c9f6428.png",
        "c695087eeeea4daead8888c2015b4c2c.png",
        "92bd5298b2ad4a4f8545fb15ea5f6aa8.png",
      ]
      return baseIconUrl + png[type]
    },
    // 切换点击坐标点icon
    setStyleIcon (i) {
      let clickIconUrl = `http://ilab-assets.oss-cn-zhangjiakou.aliyuncs.com/HanChaoMap/`
      let png = [
        "ba7a5eaced49491db3142705a5159de0.png",
        "1ab724db9e2b49aaabc79aff6b1dd10b.png",
        "439e4e1e3bb3495db90cf20ac48eabf2.png",
        "ed3d64b54c4f4e65a10b9536bb2cf5f3.png",
        "5806649ac7db49e79540202c82d9e38c.png",
      ]
      return clickIconUrl + png[i]
    },
    // 创建初始定位点图层对象数组
    initPointGeometry (argument) {
      let arr = []
      argument.map((item) => {
        arr.push({
          // 标记位置(经度，纬度，高度)
          position: new TMap.LatLng(
            item.trPosition.latitude,
            item.trPosition.longitude,
            100
          ),
          styleId: "marker" + this.active, // 定位样式
          styleIcon: "marker" + this.active,
          styleClickIcon: "c-marker" + this.active,
          isClick: false,
          ...item,
        })
      })
      return arr
    },
    // 路线定位点图层对象数组
    linePointGeometry (argument) {
      let arr = []
      argument.map((item, index) => {
        let obj = {
          position: new TMap.LatLng(item.latitude, item.longitude, 100),
          content: index + 1 + "",
        }
        obj = Object.assign(obj, item.projectSpot)
        arr.push(obj)
      })
      return arr
    },
    moveLayer () {
      var layerId = this.earthlayer.getId() // 获取图层ID
      // 根据输入 LAYER_LEVEL 常量调整 layerId 对应图层的渲染层级 ，其中layerId可以通过图层getId方法获取。
      // TMap.constants.LAYER_LEVEL 代表图层级别常量，见帮助文档（https://lbs.qq.com/webApi/javascriptGL/glDoc/docIndexMap）
      this.maps.moveLayer(layerId, TMap.constants.LAYER_LEVEL.TEXT)
    },

    //计算图层Url
    getNormalizedCoord (coord, zoom) {
      let y = coord.y
      let x = coord.x
      let tileRange = 1 << zoom
      if (y < 0 || y >= tileRange) {
        return null
      }
      if (x < 0 || x >= tileRange) {
        x = ((x % tileRange) + tileRange) % tileRange
      }
      return {
        x: x,
        y: y,
      }
    },
    // 覆盖瓦片地图
    layer () {
      let that = this
      // https://ilab-oss.arvroffer.com/HanChaoMap/tiles/7/100_50.png
      this.earthlayer = new TMap.ImageTileLayer({
        getTileUrl: function (x, y, z) {
          //拼接瓦片URL
          let url = `https://ilab-oss.arvroffer.com/HanChaoMap/${that.currentAddre.travelAbbreviation}/tiles/${z}/${x}_${y}.png`
          return url
        },
        tileSize: 256, //瓦片像素尺寸
        minZoom: 7, //显示自定义瓦片的最小级别
        maxZoom: 13, //显示自定义瓦片的最大级别
        visible: true, //是否可见
        zIndex: 1, //层级高度（z轴）
        opacity: 0.9, //图层透明度：1不透明，0为全透明
        map: this.maps, //设置图层显示到哪个地图实例中
      })
    },

    // 获取url上的参数
    getUrlParam (name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)") //构造一个含有目标参数的正则表达式对象
      let r = window.location.search.substr(1).match(reg) //匹配目标参数
      if (r != null) return decodeURIComponent(r[2])
      return null //返回参数值
    },
    //搜素
    onfocus () {
      this.$router.push("/search")
    },
  },
};
</script>
<style lang="less" scoped>
// 路线弹框样式
.pathWrap {
  .li {
    margin: 0.2rem 0;
    background: #ddd;
    line-height: 0.45rem;
    padding: 5px;

    .id {
      display: inline-block;
      margin: 0 0.2rem;
      margin-right: 0;
      width: 0.45rem;
      height: 0.45rem;
      border-radius: 50%;
      color: #fff;
      background: #0a9df1;
      font-size: 0.24rem;
      text-align: center;
    }

    .text {
      display: inline-block;
      line-height: 0.5rem;
    }

    .text:last-child .icon {
      display: none;
    }

    p {
      display: inline-block;
      margin: 0.1rem 0.2rem;
      font-size: 0.28rem;
    }

    .icon {
      position: relative;
      top: 0.04rem;
      margin: 0 0.1rem;
      width: 0.28rem;
      height: 100%;
      font-size: 0.28rem;
      line-height: 0.5rem;
    }
  }
}

/*弹出框*/
.mask {
  position: absolute;
  top: 100px;
  left: 0;
  height: 400px;
  width: 100%;
  z-index: 9999;
  margin-top: 215px;
  background: rgba(255, 255, 255, 1);
}

.top {
  // width: 77px;
  height: 25px;
  // background-color: antiquewhite;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
  position: relative;
}

.pop_content {
  width: 100%;
  height: 150px;
  // background-color: pink;
  position: absolute;
  margin-top: 50px;
}

.pop_content .con_one,
.con_two {
  width: 100%;
  height: 100px;
  background-color: rgba(221, 221, 221);
  margin-bottom: 10px;
}

.pop_content .con_one .first {
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #079fe3;
  font-size: 18px;
  margin-left: 10px;
  /* padding: 1px 1px; */
  text-align: center;
}

.pop_content .con_one .txt {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 10px;
}

.pop_content .con_two .first {
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #079fe3;
  font-size: 18px;
  margin-left: 10px;
  /* padding: 1px 1px; */
  text-align: center;
}

.pop_content .con_two .txt {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 10px;
}

.pathbtn {
  margin-left: 5px;
  width: 60px;
  position: absolute;
  margin-top: 10px;
  // background-color: pink;
  font-size: 16px;
  color: #079fe3;
}

.btn {
  background-color: #fff;
  border-radius: 4px;
  margin-left: 8px;
  // border: 1px solid blue;
  // padding: 4px 12px;
}

.share {
  width: calc(100% - 20px);
  height: 273px;
  position: fixed;
  left: 10px;
  z-index: 100;
  transition: bottom 0.5s ease-in;
}

.share .share-opt {
  width: 100%;
  height: 210px;
  border-radius: 8px;
}

.share .btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  color: #333333;
  font-size: 14px;
  border: none;
  margin-top: 10px;
}

.home {
  max-height: 92vh;
  overflow: hidden;
  position: relative;
}

.recommend {
  position: absolute;
  bottom: 10vh;
  left: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #fff;
  z-index: 2000;

  .box {
    margin: 0 auto;
    width: 0.4rem;
    height: 0.4rem;
    background: url("../assets/icon/home/luxian.png") no-repeat center center;
    background-size: 100% 100%;
  }

  span {
    display: block;
    text-align: center;
  }
}

.share {
  position: absolute;
  bottom: 10vh;
  right: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #fff;
  z-index: 2000;

  .box {
    margin: 0 auto;
    width: 0.4rem;
    height: 0.4rem;
    text-align: center;
    line-height: 0.5rem;
    color: #0a9df1;
  }

  span {
    display: block;
    text-align: center;
  }
}

header {
  // height: 1rem;
  height: 2rem;
  position: relative;
  // overflow: hidden;

  .headerNav {
    display: flex;

    .search {
      flex-grow: 1;
    }
  }

  .nav {
    width: 100%;
    height: 0.8rem;

    /deep/ .van-tabbar--fixed {
      position: static;
      margin-top: 0;
      width: 100%;
      height: 0.8rem;
      font-size: 0.22rem;
    }

    /deep/ van-tabbar-item__icon {
      width: 100%;
    }

    /deep/ .van-tabbar-item__text {
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }

    /deep/ .van-tabbar-item__icon img {
      height: 0.5rem;
    }
  }
}

#mapContainer {
  width: 100vw;
  // height: 84vh;
  height: 80vh;
}

#mapContainer {
  div:first-child {
    div:first-child + div {
      display: none;
    }
  }
}

//  去掉腾讯地图左下角logo
/deep/ #mapContainer > div > div:nth-child(2) > div:nth-child(1) {
  display: none !important;
}

//  去掉腾讯地图右上角控件
/deep/ #mapContainer > div > div:nth-child(2) > div:nth-child(2) {
  display: none !important;
}
</style>
