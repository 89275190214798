<template>
  <div class="selectWrap">
    <div class="inputWrap">
      <input readonly style="display: inline; max-width: 0.8rem" type="text" v-model="value" @focus="handleFocus" />
      <span class="inputIcon">
        <van-icon :name="iconType" />
      </span>
    </div>
    <ul class="options" @click="handleCheck" v-show="isSelected">
      <li :class="{ active: index == activeLiIndex }" v-for="(item, index) in options" :key="index" :name="item">
        {{ item[itemName] }}
      </li>
    </ul>
  </div>
</template>

<script>
import { NavBar } from 'vant';

export default {
  data() {
    return {
      iconType: "arrow",
      isSelected: false,
      activeLiIndex: "",
      value: "",
      selectedData: {
        traveName: null,
      },
    };
  },
  created() {
    if (!this.selectedData[this.itemName]) {
      this.selectedData = this.options[0];
    }
    if (this.selectedData instanceof Object) {
      this.value = this.selectedData[this.itemName];
    }
    if (this.itemName === "language") {
      this.options.map((item, index) => {
        if (this.$i18n.locale == item.abbreviation) {
          this.selectedData = item;
          this.activeLiIndex = index;
          this.value = this.selectedData[this.itemName];
        }
      });
    }
  },
  props: {
    options: {
      type: Array,
      default: [
        {
          traveName: "还没有数据",
        },
      ],
    },
    itemName: {
      default: "traveName",
    },
    switchId: {
      type: Number,
      default: 4
    }
  },
  mounted(){
    this.options.map((item,index)=>{
      if(this.switchId==item.id){
        this.selectedData=item; 
        console.log(item,1);
        this.$emit("checked", this.selectedData);
      }
    })
    console.log(this.switchId);
  },
  methods: {
    handleFocus() {
      this.iconType = "arrow-down";
      this.isSelected = true;
    },
    handleBlur() {
      this.iconType = "arrow";
      this.isSelected = false;
    },
    handleCheck(e) {
      this.options.map((item, index) => {
        if (item[this.itemName] === e.target.innerText) {
          this.selectedData = item;
          this.activeLiIndex = index;
        }
      });
      this.value = this.selectedData[this.itemName];
      this.handleBlur();
      this.$emit("checked", this.selectedData);
    },
  },
};
</script>
<style lang='less' scoped>
.selectWrap {
  position: relative;
  margin: auto 0.1rem;
  margin-right: -0.1rem;

  .inputWrap {
    display: flex;
    justify-content: space-between;
    // background: #f7f8fa;
    background: white;
    border: 0.001rem solid black;
    border-radius: 0.05rem;
    padding-right: 0.04rem;

    input {
      flex-grow: 1;
      margin: auto 0.1rem;
    }
  }

  ul {
    position: absolute;
    top: 0.49rem;
    left: 0rem;
    width: 99%;
    z-index: 999999;
    min-height: 1rem;
    max-height: 3rem;
    overflow-y: scroll;
    background: white;
    box-shadow: 0.05rem 0.05rem 0.1rem #dddddd, -0.05rem 0.05rem 0.1rem #dddddd;

    // border-radius: 0rem 0rem 0.1rem 0.1rem;
    li {
      margin: 0.1rem auto;
      padding: 0 0.1rem;
    }
  }
}

.active {
  background: rgb(95, 209, 238);
  color: white;
}
</style>