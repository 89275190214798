import Vue from "vue";

// 获取本地存储中的登录信息
export function getLoginInfo() {
    const loginInfo = JSON.parse(localStorage.getItem('adminInfo') || '{}');
    return loginInfo;
}
export function teMap() {
    return new Promise(function (resolve, reject) {
        window.init = function () {
            resolve(qq)
        }
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'http://map.qq.com/api/js?v=2.exp&callback=init&key=O7DBZ-YR4C3-XPI3J-YNKSL-HCT5F-ICFZA'
        script.onerror = reject
        document.head.appendChild(script)
    })
}
Vue.prototype.tetMap = teMap